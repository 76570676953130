<template>
  <div class="login-page">
    <section>
      <div class="loginArticle">
        <article class="loginSection">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            "
            class="mb-4"
            @click="$router.push({ path: '/' })"
          >
            <img
              src="../../assets/images/acacia-light-logo-svg.svg"
              width="140px"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="form-group">
            <label for="email" style="color: #f5efdd">Email</label>
            <input
              type="text"
              v-model="email"
              @keyup.enter="loginUser"
              name="email"
              class="form-control"
              placeholder="Enter your email"
            />
          </div>
          <div class="form-group">
            <label htmlFor="password" style="color: #f5efdd">Password</label>
            <input
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              @keyup.enter="loginUser"
              name="password"
              class="form-control"
              placeholder="Enter your password"
            />
            <span
              style="color: #ddd0a9; cursor: pointer; margin-right: 10px"
              @click="togglePasswordVisibility"
              class="fa fa-fw field-icon toggle-password"
              :class="showPassword ? 'fa-eye' : 'fa-eye-slash'"
            ></span>
          </div>
          <div class="form-group">
            <div v-if="isBusy" class="d-flex justify-content-center">
              <ScaleOut />
            </div>
            <button class="btn btn-block btn-black" @click="loginUser" v-else>
              Login
            </button>
          </div>
          <div class="text-center mt-5">
            <small style="color: #ddd0a9; font-size: 11px">
              &#169; {{ currentYear }} | SmartLodge | Build V2.3.1
            </small>
          </div>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
import ScaleOut from "@/components/backoffice/scale-out-component.vue";

export default {
  components: {
    ScaleOut,
  },

  data() {
    return {
      isBusy: false,
      email: "",
      password: "",
      showPassword: false,
    };
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },

  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword; // Toggle the showPassword  state
    },
    async loginUser() {
      try {
        this.isBusy = true;
        let request = await this.$http.post("users/login", {
          email: this.email,
          password: this.password,
        });
        if (
          request.data.success &&
          request.data.message == "Login successful"
        ) {
          this.$notify({
            title: "Login Successful",
            message: "You were successfully logged in.",
            type: "success",
          });
          this.$store.commit("LOGIN_USER", request.data);
          if (request.data.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("user", JSON.stringify(request.data.token));
            // this.$store.commit("LOGIN_USER", request.data);
            this.$router.replace({ path: "/" });
            location.reload(true);
          }
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        if (error.message === "Network Error") {
          return this.$notify({
            title: "Connection Failed",
            message:
              "Unable to Connect. Please check your Internet Connection and try again.",
            type: "error",
          });
        }

        alert(error);
        //If the API returns other status codes besides 2xx, Handle these responses
        if (error.response) {
          if (error.response.data.message === "Invalid email or password") {
            return this.$notify({
              title: "Invalid",
              message: "Invalid email or password",
              type: "warning",
            });
          }
        } else {
          return this.$notify({
            title: "Login Failed",
            message: "Unable to Complete login now. Please try again.",
            type: "error",
          });
        }
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style scoped>
.login-page {
  height: 100vh;
  width: 100%;
  background-color: #ddd0a9;
  background-image: url(../../assets/images/acacia_mweya1.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.loginArticle {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginSection {
  width: 40%;
  background-color: #17351ef9;
  color: white;
  padding: 50px;
  -webkit-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  -webkit-transition: box-shadow 0.5s;
  transition: box-shadow 0.5s;
  border-radius: 15px;
}

.loginSection:hover {
  -webkit-box-shadow: 0px 0px 1.5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 1.5px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 1.5px -1px rgba(0, 0, 0, 0.75);
  transition: 1s;
}
.btn-black {
  background-color: #cdc284;
  height: 7vh;
  border-radius: 5px;
  color: black;
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}
.btn-black:hover {
  background-color: #cfc27a; /* Change color to orange */
  transform: scale(1.02); /* Slightly grow the button */
}
.form-control::placeholder {
  font-size: 12px; /* Adjust this value as needed */
}

.form-control {
  background-color: #f5efdd; /* Set your desired background color */
  font-size: 14px; /* Adjust input text size */
  padding: 10px; /* Optional: Add padding for better appearance */
  border-radius: 5px; /* Optional: Rounded corners */
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .loginSection {
    width: 90%;
    padding: 50px 10px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .loginSection {
    width: 90%;
    padding: 50px 10px;
  }
}
</style>